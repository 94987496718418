'use strict';

var $end_date_input = $('input[type=date]');

function dateToDate(date) {
    let params = date.split('-');
    params[1]--; // months are zero indexed
    return new Date(...params);
};

module.exports = {
    
    checkDateInput: function() {
        $end_date_input.on('input propertychange', function(){
            if(dateToDate(this.value) > dateToDate(this.max)){
                this.value = this.max;
            }
        });
    },
};
