'use strict';
var footerTriggered = $('.footercontent .container .footer-bottom');
var SCROLL_NUMBER = 4;

if ($(window).width() < 768) {
    footerTriggered.append('<div class="back-top-btn" id="backTopBtn"></div>');
    $(window).on('scroll', function () {
        if (isScrolledIntoView('.footercontent') && (getPageHeight() / getWindowHeight()) >= SCROLL_NUMBER) {
            $('#backTopBtn').addClass('show');
        } else {
            $('#backTopBtn').removeClass('show');
        }
    });
    var backTopBtn = $('#backTopBtn');
    backTopBtn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, '300');
    });
}
function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height() - 70;
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return ((elemTop < docViewBottom));
}

function getWindowHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}
function getPageHeight() {
    return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
    );
}