'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');
var initsGeneralSelect = require('./initInput');

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
 function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.modal-body');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * This method sets the handler for the change language modal ok button
 */
function setChangeLanguageModalOkButtonHandler() {
    $('body').on('click', '.change-language-modal-ok-button', function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        var queryString = $('.page').data('querystring');
        var selectedOption = $('#changeLanguageSelect').find(":selected");
        var localeCode = $(selectedOption).data('locale');
        var localeCurrencyCode = $(selectedOption).data('currencycode');
        var url = $(this).data('url');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString: queryString,
                CurrencyCode: localeCurrencyCode,
                action: action
            },
            success: function (response) {
                $.spinner().stop();
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    accessibility: function () {
        keyboardAccessibility('.navbar-header .country-selector',
            {
                40: function ($countryOptions) { // down
                    if ($(this).is(':focus')) {
                        $countryOptions.first().focus();
                    } else {
                        $(':focus').next().focus();
                    }
                },
                38: function ($countryOptions) { // up
                    if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
                        $(this).focus();
                        $(this).removeClass('show');
                    } else {
                        $(':focus').prev().focus();
                    }
                },
                27: function () { // escape
                    $(this).focus();
                    $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
                },
                9: function () { // tab
                    $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
                }
            },
            function () {
                if (!($(this).hasClass('show'))) {
                    $(this).addClass('show');
                }
                return $(this).find('.dropdown-country-selector').children('a');
            }
        );
    },
    openChangeLanguageModal: function () {
        $('body').on('click', '.open-country-selector-modal-button', function (e) {
            e.preventDefault();

            var editProductUrl = $(this).data('action');

            $('#modalChangeLanguage').spinner().start();
            
            $.ajax({
                url: editProductUrl,
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    var parsedHtml = parseHtml(data.renderedTemplate);

                    $('#modalChangeLanguage .modal-body').empty();
                    $('#modalChangeLanguage .modal-body').html(parsedHtml.body);
                    $('#modalChangeLanguage .modal-footer').html(parsedHtml.footer);
                    $('#modalChangeLanguage .modal-header .close .sr-only').text(data.closeButtonText);
                    $('#modalChangeLanguage .enter-message').text(data.enterDialogMessage);
                    $('#modalChangeLanguage').modal('show');
                    setChangeLanguageModalOkButtonHandler();
                    // $('body').trigger('editwishlistproduct:ready');
                    initsGeneralSelect.initGeneralSelect($('.init-general-select'));
                    $.spinner().stop();
                },
                error: function () {
                    $('#modalChangeLanguage').spinner().stop();
                }
            });
        });
    }
};
