'use strict';

require('select2/dist/js/select2');
require('libphonenumber-js');

const parsePhoneNumber = require('libphonenumber-js/max');
const examples =  require('libphonenumber-js/mobile/examples');

function formatNumberInternational(country, number) {
    if(country && number){
        var parseNumber = parsePhoneNumber(country + number);
        if(parseNumber) {
            var internationalNumber = parseNumber.format('INTERNATIONAL', { spaceSeparatedComponents: true });
        } else {
            var internationalNumber = country + ' ' + number;
        }
        return internationalNumber;
    }
}

function formatState (opt) {
    if (!opt.id) {
        return opt.text.toUpperCase();
    } 
    var optimage = $(opt.element).attr('data-image');
    if(!optimage){
       return opt.text.toUpperCase();
    } else {           
        var templateHtml = `
        <div class="select2-wrap-content d-flex align-items-center mr-4">
            <div>
                <img src="${optimage}" height="24px" width="24px"  />
            </div>
            <div class="ml-4">
                ${opt.id}
            </div>
        </div>
        `
        var $opt = $(templateHtml)
        return $opt;
    }
};

function formatResult (opt) {
    if (!opt.id) {
        return opt.text.toUpperCase();
    } 
    var optimage = $(opt.element).attr('data-image');
    if(!optimage){
       return opt.text.toUpperCase();
    } else {            
        var templateHtml = `
        <div class="select2-wrap-content d-flex align-items-center mr-4">
            <div>
                <img src="${optimage}" height="24px" width="24px"  />
            </div>
            <div class="ml-4">
                ${opt.text}
            </div>
        </div>
        `
        var $opt = $(templateHtml)
        return $opt;
    }
};

function initPrefixSelect(selectPrefixPhone) {
    if(selectPrefixPhone) {
        selectPrefixPhone.each(function(index, element) {
            if($(this).is(':visible')) {
                $(this).select2({
                    templateResult: formatResult,
                    templateSelection: formatState,
                    width: 'auto',
                    dropdownParent: $(this).parent().find('.select-dropdown-content')
                });
            }
        });
    }
}

// use in inputfield isml for init general select
// xhtmlclass="init-general-select"

function initGeneralSelect(selectGeneral) {
    if(selectGeneral) {
        selectGeneral.each(function(index, element) {
            if(!$(this).hasClass("select2-hidden-accessible")) {
                $(this).select2({
                    minimumResultsForSearch: -1,
                    placeholder:  $(this).data('placeholder') || "Select",
                    allowClear: true,
                    width: 'null',
                    dropdownParent: $(this).parent().find('.select-dropdown-content')
                });
            }
        });
    }
}

$(document).ready(function() {
    var selectPrefixPhone = $("select.prefixPhone");
    var generalSelect = $('select.init-general-select');
    if(selectPrefixPhone) {
        initPrefixSelect(selectPrefixPhone);
    }
    if(generalSelect) {
        initGeneralSelect(generalSelect);
    }
});

$(document).ajaxComplete(function() {
    var selectPrefixPhone = $("select.prefixPhone");
    if(selectPrefixPhone) {
        initPrefixSelect(selectPrefixPhone);
    }
});

$('.btn-show-details').on('click', function () {
    var parentBox = $(this).closest('.checkout-address-item');
    var prefixSelect = parentBox.find('.prefixPhone');
    initPrefixSelect(prefixSelect);
});

$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var target = $(e.target).attr('href');
    var selectToInit = $(target).find('.prefixPhone');
    initPrefixSelect(selectToInit);
});

$('.switch > input[type=checkbox]').on('change', function(e){
    var selectToRender = $(this).closest('.form-group').find('select.init-general-select');
    /* if(selectToRender && $(this).is(':checked')) {
        selectToRender.each(function(index, element) {
            if(!$(this).hasClass("select2-hidden-accessible")) {
                $(this).select2({
                    minimumResultsForSearch: -1,
                    width: '100%',
                    placeholder:  $(this).data('placeholder') || "Select",
                    allowClear: false,
                    dropdownParent: $(this).parent().find('.select-dropdown-content')
                });
            }
        });
    } */
})

$('.addressSelector').on('change', function (e) {
    var selectPrefix = $(this).closest('.checkout-address-item').find('.prefixPhone');
    initPrefixSelect(selectPrefix);
});

$('select.prefixPhone').on('change', function(){
    var countrySelected = $(this).find('option:selected').attr('data-country') && $(this).find('option:selected').attr('data-country').toUpperCase();
    var inputPhone = $(this).parent().find('.phone-number-input');
    var internationalPhoneNumber = $(this).parent().find('.internationalPhoneNumber');
    inputPhone.val("");
    internationalPhoneNumber.val("");
    var formattingPlaceholder = parsePhoneNumber(examples[countrySelected], countrySelected);
    inputPhone.attr('placeholder', formattingPlaceholder.formatNational());
    $(this).closest('.custom-phone-multiinput').find('.phone-number-input').removeClass('is-invalid');
    $(this).closest('.custom-phone-multiinput').find('.invalid-feedback').text('');
});

$('.phone-number-input').on('focusout', function(e) {
    var $this = $(this);
    var digitPhone = $(this).val();
    var country = $(this).parent().find('select option:selected').attr('data-country') && $(this).parent().find('select option:selected').attr('data-country').toUpperCase();
    var generalErrorCh = $this.attr('data-general-error') || "Error invalid number phone CH";
    var generalErrorWorld = $this.attr('data-general-world-error') || "Error invalid number phone all country";
    var parseNumber = parsePhoneNumber(digitPhone, country);


    if(country && country == 'CH') {
        if(parseNumber && !parseNumber.isValid() && digitPhone.length >= 10) {
            $this.addClass('is-invalid');
            $this.closest('.custom-phone-multiinput').find('.invalid-feedback').text(generalErrorCh);
        } else if(digitPhone && digitPhone.length < 10) {
            $this.addClass('is-invalid');
            $this.closest('.custom-phone-multiinput').find('.invalid-feedback').text(generalErrorCh);
        } else {
            $this.removeClass('is-invalid');
            $this.closest('.custom-phone-multiinput').find('.invalid-feedback').text('');
        }
    } else {
        if( (parseNumber && !parseNumber.isValid()) || !parseNumber ) {
            $this.addClass('is-invalid');
            $this.closest('.custom-phone-multiinput').find('.invalid-feedback').text(generalErrorWorld);
        } else {
            $this.removeClass('is-invalid');
            $this.closest('.custom-phone-multiinput').find('.invalid-feedback').text('');
        }
    }
})

$('.phone-number-input').on('input', function(e) {
    var digitPhone = $(this).val();
    var country = $(this).parent().find('select option:selected').attr('data-country') && $(this).parent().find('select option:selected').attr('data-country').toUpperCase();
    var countryPrefix = $(this).parent().find('select option:selected') && $(this).parent().find('select option:selected').val();
    if(digitPhone && country) {
        const asYouType = new parsePhoneNumber.AsYouType(country);
        var digitingNumber = asYouType.input(digitPhone.toString());
        $(this).val(digitingNumber);
        var checkDigitPhone = digitPhone.replace(/\s/g, '');

        if(country && country == 'CH') {
            if (checkDigitPhone && checkDigitPhone.length >= 10) {
                var valInput = $(this).val() && $(this).val().replace(/\s/g, '').slice(0,10);
                const asYouType2 = new parsePhoneNumber.AsYouType(country);
                var newDigitingNumber = asYouType2.input(valInput.toString());
                $(this).val(newDigitingNumber);
            }
        } else {
            $(this).attr('minlength', 1);
            $(this).attr('maxlength', 30);
        }

        var parseNumber = parsePhoneNumber(digitPhone, country);
        if(parseNumber && countryPrefix && digitPhone){
            var internationalNumber = parseNumber.format('INTERNATIONAL', { spaceSeparatedComponents: true });
            $(this).next('.internationalPhoneNumber').val(internationalNumber || '');
        } else {
            $(this).next('.internationalPhoneNumber').val(countryPrefix + ' ' + digitPhone);
        }
    } else {
        $(this).next('.internationalPhoneNumber').val('');
    }
});

$(document).on('phoneNumbeFormatted', function (e, phoneNumbeFormatted) {
    var shippingPhone = $('.summary-details.shipping .phone').html();
    var shippingPrefixPhone = $('.summary-details.shipping .prefixPhone').html() || '+41'
    var billingPhone = $('.summary-details.billing .phone').html();
    var billingPrefixPhone = $('.summary-details.billing .prefixPhone').html() || '+41';


    if (shippingPhone && billingPhone) {
        phoneNumbeFormatted.shippingPhone = parsePhoneNumber(shippingPrefixPhone.replace(/\s+/g, '') + shippingPhone.replace(/\s+/g, '')).format('INTERNATIONAL', { spaceSeparatedComponents: true });
        phoneNumbeFormatted.billingPhone = parsePhoneNumber(billingPrefixPhone.replace(/\s+/g, '') + billingPhone.replace(/\s+/g, '') ).format('INTERNATIONAL', { spaceSeparatedComponents: true })
    }
});

$(document).on('phoneNumbeInternationalFormatted', function (e) {
    var shippingPhone =  $(e.currentTarget).find('.summary-details.shipping .phone').html().replace(/\s+/g, '');
    var shippingPrefixPhone = $(e.currentTarget).find('.summary-details.shipping .prefixPhone').html().replace(/\s+/g, '') || '+41'
    var billingPhone = $(e.currentTarget).find('.summary-details.billing .phone').html().replace(/\s+/g, '');
    var billingPrefixPhone = $(e.currentTarget).find('.summary-details.billing .prefixPhone').html().replace(/\s+/g, '') || '+41';
    var shippingInternationalNumber = $(e.currentTarget).find('.summary-details.shipping .internationalPhone');
    var billingInternationalNumber = $(e.currentTarget).find('.summary-details.billing .internationalPhone');

    shippingInternationalNumber.text(formatNumberInternational(shippingPrefixPhone, shippingPhone));
    billingInternationalNumber.text(formatNumberInternational(billingPrefixPhone, billingPhone));
});

module.exports = {
    initGeneralSelect: initGeneralSelect,
    initPrefixSelect: initPrefixSelect,
    formatNumberInternational: formatNumberInternational
};