'use strict';

$('input[type=checkbox]').on('change', function(){
    var name = $(this).attr('name');
    if($(this).is(':checked')){
    $('input[name= "' +name +'"]').val(true);
        $(this).val(true);
    }
    else{
       $(this).val(false);
       $('input[name= "' +name +'"]').val(false);
    }
});