"use strict";

require('swiper/element/bundle');

import { Swiper } from "swiper";

class SwiperCombineSlider {
  constructor(element1, element2) {
    var ctx = this;
    var sliderOne = $(element1);
    var sliderTwo = $(element2);

    // INIT SLIDER WITH CONTENT (slider vertical)
    sliderOne = new Swiper(".swiper", {
        loop: false,
        allowTouchMove: true,
        //cssMode: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                direction: "horizontal",
                //cssMode: true
            },
            640: {
                slidesPerView: 3,
                direction: "vertical",
            }
        },
        on: {
            init: function () {
            this.slides.forEach(function (slide, index) {
                slide.setAttribute('data-slide-index', index);
            });
            }
        }
    });

    // INIT SLIDER ONLY CONTROLLER (pagination horizontal)
    sliderTwo = new Swiper(".swiper-control", {
      direction: 'horizontal',
      breakpoints: {
          320: {
              slidesPerView: 1,
          },
          640: {
              slidesPerView: 3,
          }
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 4
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    });

    sliderOne.controller.control = sliderTwo;
    sliderTwo.controller.control = sliderOne;

  }
}

export default function() {
  $("body").on("storesList:build", () => {
    new SwiperCombineSlider('.swiper', '.swiper-control');
  })
};