'use strict';

import * as toastr from "toastr";
window.toastr = toastr;

//https://codeseven.github.io/toastr/demo.html
//how to use:
//isml: <div class="toastr-custom" data-type="info" data-msg="lorem ipsum lorem ipsum"></div>
//js: window.toastr.info('show message');
class Toastr {
    constructor(elem) {
        this.$elem = $(elem);
        this.getOpts();
    }
    getOpts() {
        var data = this.$elem.data();
        if (data) {
            var msg = data['msg'] || this.$elem.html();
            var opts = {
                closeButton: data['closeButton'] || true,
                debug: data['debug'] || false,
                newestOnTop: data['newestOnTop'] || true,
                progressBar: data['progressBar'] || false,
                positionClass: data['positionClass'] || "toast-top-center", //toast-top-right, toast-bottom-right, toast-bottom-left, toast-top-left, toast-top-full-width, toast-bottom-full-width, toast-top-center, toast-bottom-center
                preventDuplicates: data['preventDuplicates'] || true,
                iconClasses: { //to choose background
                    error: 'toast-error bg-error-100',
                    info: 'bg-info',
                    success: 'toast-success',
                    warning: 'bg-warning'
                },
                toastClass: 'toast',
                titleClass: data['titleClass'] || 'toast-title',
                messageClass: data['messageClass'] || 'toast-message',
                closeClass: data['closeClass'] || 'toast-close-button',
                showDuration: data['showDuration'] || 300,
                onclick: data['onclick'] || null,
                hideDuration: data['hideDuration'] || 1000,
                timeOut: data['timeOut'] || 7000,
                extendedTimeOut: data['extendedTimeOut'] || 1000,
                showEasing: data['showEasing'] || "swing",
                hideEasing: data['hideEasing'] || "linear",
                showMethod: data['showMethod'] || "fadeIn",
                hideMethod: data['hideMethod'] || "fadeOut",
                tapToDismiss: data['tapToDismiss'] || false
            };
            // toastr.remove();
            toastr.options = opts;
            toastr.options.closeHtml = '<button class="custom-close-icon"></button>';
            toastr[data['type'] || 'info'](msg); //type: info, warning, success, error
        }
    }
}

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "7000",
    "hideDuration": "500",
    "timeOut": "7000",
    "extendedTimeOut": "10000000000000000000000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
    "tapToDismiss": false,
    "closeOnHover": false,
    onShown: function() {
        if($('header').is(':visible') && $('header').css('top') === '0px') {
            $('#toast-container').css("top", "90px");
        } else {
            $('#toast-container').css("top", "20px");
        }
    },
}

toastr.options.closeHtml = '<button class="custom-close-icon"></button>';

export default function() {
    $.each($('.toastr-custom'), function () {
        new Toastr(this);
    });
}