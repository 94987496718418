'use strict';

var $openProfileMenuClass = '.js-profile-trigger';
var $openProfileMenuTrigger = $('.js-profile-trigger');
var $contentProfileMenu = $('.js-profile-menu-content');
var DELTA = 210; //scrollable px parameters
var BREAK_POINT_MBL = '769'; //breakpoint md
var LAST_SCROLL_TOP = 0;
var $navbarHeight = $('header').outerHeight();
var $isMobile = $(window).width() <= BREAK_POINT_MBL;

function isScrollDownStickyHeader() {
    $(window).scroll(function (event) {
        var initScrollTop = $(this).scrollTop();
        if (Math.abs(LAST_SCROLL_TOP - initScrollTop) <= DELTA)
            return;
        if ((initScrollTop > LAST_SCROLL_TOP) && (LAST_SCROLL_TOP > 0)) {
            $("header").css("top", "-" + $navbarHeight + "px");
        } else {
            $("header").css("top", "0");
        }
        LAST_SCROLL_TOP = initScrollTop;
    });
}

module.exports = function () {
    $(document).on('click',function(event){
        var $target = $(event.target);
        if(!$target.is($openProfileMenuClass)){
            $contentProfileMenu.removeClass("active");
        }
    });

    $openProfileMenuTrigger.on('click', function(e){
        $contentProfileMenu.toggleClass('active');
        e.stopPropagation();
    })

    //sticky header show & hide scrolldown mbl only
    if ($isMobile) {
        isScrollDownStickyHeader();
    }

};
