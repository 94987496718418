'use strict';
class Carousel {
    constructor(element) {
        var ctx = this;
        var slidesContainer = $(element);
        var matchMedia = true;
        var opt = ctx.getOpts(slidesContainer);

        if (matchMedia) {
            slidesContainer.not('.slick-initialized').slick(opt);
        }

        $(window).on('resize orientationchange', function () {
            slidesContainer.slick('resize');
        });

        $('.nav-pills a, .nav-pills button').on('shown.bs.tab', function (e) {
            let carousel;
            if (
                $(e.target)
                .parents('.tabs-wrapper')
                .find('.tab-pane.active .product-carousel').length > 0
            ) {
                carousel = $(e.target)
                    .parents('.tabs-wrapper')
                    .find('.tab-pane.active .product-carousel');
            }
            if (
                $(e.target)
                .parents('.tabs-wrapper')
                .find('.tab-pane.active .categories-carousel').length > 0
            ) {
                carousel = $(e.target)
                    .parents('.tabs-wrapper')
                    .find('.tab-pane.active .categories-carousel');
            }
            if (
                $(e.target)
                .parents('.tabs-wrapper')
                .find('.tab-pane.active .carousel').length > 0
            ) {
                carousel = $(e.target)
                    .parents('.tabs-wrapper')
                    .find('.tab-pane.active .carousel');
            }
            if (carousel) {
                carousel.slick('setPosition');
            }
        });
    }

    setResponsiveSetting(opts, res, option, value) {
        if (typeof opts.responsive == 'undefined') {
            opts.responsive = [];
        }

        var resPresent = false;

        $.each(opts.responsive, function (i, resolution) {
            if (resolution['breakpoint'] == res) {
                resPresent = true;
            }
        });

        if (!resPresent) {
            opts.responsive.push({
                breakpoint: res,
                settings: {}
            });
        }

        $.each(opts.responsive, function (i, resolution) {
            if (resolution['breakpoint'] == res && resolution['settings'] != 'unslick') {
                resolution['settings'][option] = value;
                if (value == 'unslick') {
                    resolution['settings'] = value;
                }
            }
        });

        return opts;
    }

    getOpts($element) {
        var data = $element.data();
        var customPaging;

        if (data) {
            var context = data['context'];
            var scope = context ? $element.closest(context) : $element;
            var opts = {};

            if (data && !data['slick']) {
                if (data['thumbs']) {
                    customPaging = function (slick, i) {
                        var $elem = $(slick.$slides[i]).find('img');
                        var src = $elem.length ? $elem.data('thumburl') || $elem.attr('src') : undefined;
                        var alt = 'thumb-' + i;
                        var width ='700';
                        var height ='700';
                        return '<img data-src="' + src + '" alt="' + alt + '" width="' + width + '" height="' + height + '" class="lazyload" />';
                    };
                }

                if (data['paged']) {
                    $element.addClass('paged');
                    customPaging = function (slick, i) {
                        var txt = i + 1 + '/' + slick.$slides.length;
                        return $('<span class="page"/>').text(txt);
                    };
                }

                if (data['prevArrow']) {
                    //check if is html code or a class string
                    if (/<[^>]*>/.test(data['prevArrow'])) {
                        data['prevArrow'] = scope.find(data['prevArrow']);
                    } else {
                        data['prevArrow'] = $(data['prevArrow']).length ? $(data['prevArrow']) : '';
                    }
                }

                if (data['nextArrow']) {
                    //check if is html code or a class string
                    if (/<[^>]*>/.test(data['nextArrow'])) {
                        data['nextArrow'] = scope.find(data['nextArrow']);
                    } else {
                        data['nextArrow'] = $(data['nextArrow']).length ? $(data['nextArrow']) : '';
                    }
                }

                if (data['asNavFor']) {
                    data['asNavFor'] = scope.find(data['asNavFor']);
                }

                opts = {
                    accessibility: typeof data['accessibility'] != 'undefined' ? data['accessibility'] : true, // Enables tabbing and arrow key navigation
                    adaptiveHeight: typeof data['adaptiveHeight'] != 'undefined' ? data['adaptiveHeight'] : false, // Enables adaptive height for single slide horizontal carousels.
                    asNavFor: data['asNavFor'] || null, // Set the slider to be the navigation of other slider (Class or ID Name)
                    autoplay: typeof data['autoplay'] != 'undefined' ? data['autoplay'] : true, // Enables Autoplay
                    autoplaySpeed: data['autoplaySpeed'] || 3000, // Autoplay Speed in milliseconds
                    appendDots: data['appendDots'],
                    appendArrows: data['appendArrows'] || $element,
                    arrows: typeof data['arrows'] != 'undefined' ? data['arrows'] : true, // Prev/Next Arrows
                    prevArrow: data['prevArrow'] || '<button class="slick-prev"><i class="material-icons notranslate">arrow_back></i></button>',
                    nextArrow: data['nextArrow'] || '<button class="slick-next"><i class="material-icons notranslate">arrow_forward</i></button>',
                    centerMode: typeof data['centerMode'] != 'undefined' ? data['centerMode'] : false, // Enables centered view with partial prev/next slides. Use with odd numbered slidesToShow counts.
                    centerPadding: data['centerPadding'] || '50px', // Side padding when in center mode (px or %)
                    cssEase: data['cssEase'] || 'ease', // CSS3 Animation Easing
                    customPaging: customPaging || data['customPaging'],
                    dots: typeof data['dots'] != 'undefined' ? data['dots'] : customPaging != null, // Show dot indicators
                    dotsClass: data['dotsClass'] || 'slick-dots',
                    draggable: typeof data['draggable'] != 'undefined' ? data['draggable'] : true, // Enable mouse dragging
                    easing: data['easing'] || 'linear', // Add easing for jQuery animate. Use with easing libraries or default easing methods
                    edgeFriction: data['edgeFriction'] || 0.35, // Resistance when swiping edges of non-infinite carousels
                    fade: typeof data['fade'] != 'undefined' ? data['fade'] : false, // Enable fade
                    focusOnSelect: typeof data['focusOnSelect'] != 'undefined' ? data['focusOnSelect'] : false, // Enable focus on selected element (click)
                    infinite: typeof data['infinite'] != 'undefined' ? data['infinite'] : true, // Infinite loop sliding
                    initialSlide: data['initialSlide'] || 0, // Slide to start on
                    lazyLoad: data['lazyLoad'] || 'ondemand', // Set lazy loading technique. Accepts 'ondemand' or 'progressive'
                    mobileFirst: typeof data['mobileFirst'] != 'undefined' ? data['mobileFirst'] : false, // Responsive settings use mobile first calculation
                    pauseOnHover: typeof data['pauseOnHover'] != 'undefined' ? data['pauseOnHover'] : true, // Pause Autoplay On Hover
                    pauseOnDotsHover: typeof data['pauseOnDotsHover'] != 'undefined' ? data['pauseOnDotsHover'] : false, // Pause Autoplay when a dot is hovered
                    respondTo: data['respondTo'] || 'window', // Width that responsive object responds to. Can be 'window', 'slider' or 'min' (the smaller of the two)
                    rows: data['rows'] || 0, // Setting this to more than 1 initializes grid mode. Use slidesPerRow to set how many slides should be in each row.
                    slide: data['slide'] || '', // Element query to use as slide
                    slidesPerRow: data['slidesPerRow'] || 1, // With grid mode intialized via the rows option, this sets how many slides are in each grid row. dver
                    slidesToShow: data['slidesToShow'] || 2, // # of slides to show
                    slidesToScroll: data['slidesToScroll'] || 1, // # of slides to scroll
                    speed: data['speed'] || 500, // Slide/Fade animation speed
                    swipe: typeof data['swipe'] != 'undefined' ? data['swipe'] : true, // Enable swiping
                    swipeToSlide: typeof data['swipeToSlide'] != 'undefined' ? data['swipeToSlide'] : false, // Allow users to drag or swipe directly to a slide irrespective of slidesToScroll
                    touchMove: typeof data['touchMove'] != 'undefined' ? data['touchMove'] : true, // Enable slide motion with touch
                    touchThreshold: data['touchThreshold'] || 5, // To advance slides, the user must swipe a length of (1/touchThreshold) * the width of the slider
                    useCSS: typeof data['useCSS'] != 'undefined' ? data['useCSS'] : true, // Enable/Disable CSS Transitions
                    variableWidth: typeof data['variableWidth'] != 'undefined' ? data['variableWidth'] : false, // Variable width slides
                    vertical: typeof data['vertical'] != 'undefined' ? data['vertical'] : false, // Vertical slide mode
                    verticalSwiping: typeof data['verticalSwiping'] != 'undefined' ? data['verticalSwiping'] : false, // Vertical swipe mode
                    waitForAnimate: typeof data['waitForAnimate'] != 'undefined' ? data['waitForAnimate'] : true,
                    zIndex: data['zIndex'] || 10,
                    slideTitleSelector: data['slideTitleSelector'],
                    titleElement: scope.find(data['titleSelector']),
                    titleType: data['titleType']
                };

                if (data['unslickXs']) {
                    this.setResponsiveSetting(opts, 768, 'unslick', 'unslick');
                }
                if (data['unslickSm']) {
                    this.setResponsiveSetting(opts, 1024, 'unslick', 'unslick');
                }
                if (data['unslickLg']) {
                    this.setResponsiveSetting(opts, 1366, 'unslick', 'unslick');
                    this.setResponsiveSetting(opts, 2000, 'unslick', 'unslick');
                }

                if (data['slidesToShowLg']) {
                    this.setResponsiveSetting(opts, 1366, 'slidesToShow', data['slidesToShowLg']);
                    this.setResponsiveSetting(opts, 1366, 'slidesToScroll', data['slidesToScrollLg'] || 1);
                }

                if (data['slidesToShowXl']) {
                    this.setResponsiveSetting(opts, 1440, 'slidesToShow', data['slidesToShowXl']);
                    this.setResponsiveSetting(opts, 1440, 'slidesToScroll', data['slidesToScrollXl'] || 1);
                }

                if (data['slidesToShowMd']) {
                    this.setResponsiveSetting(opts, 1366, 'slidesToShow', data['slidesToShowMd']);
                    this.setResponsiveSetting(opts, 1366, 'slidesToScroll', data['slidesToScrollMd'] || 1);
                }

                if (data['slidesToShowSm']) {
                    this.setResponsiveSetting(opts, 1024, 'slidesToShow', data['slidesToShowSm']);
                    this.setResponsiveSetting(opts, 1024, 'slidesToScroll', data['slidesToScrollSm'] || 1);
                }

                if (data['slidesToShowXs']) {
                    this.setResponsiveSetting(opts, 768, 'slidesToShow', data['slidesToShowXs']);
                    this.setResponsiveSetting(opts, 768, 'slidesToScroll', data['slidesToScrollXs'] || 1);
                }

                if (typeof data['appendDotsXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'appendDots', data['appendDotsXs']);
                }

                if (typeof data['verticalXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'vertical', data['verticalXs']);
                }

                if (typeof data['verticalSwipingXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'verticalSwiping', data['verticalSwipingXs']);
                }

                if (typeof data['dotsXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'dots', data['dotsXs']);
                }

                if (typeof data['dotsSm'] != 'undefined') {
                    this.setResponsiveSetting(opts, 1024, 'dots', data['dotsSm']);
                }

                if (typeof data['arrowsXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 360, 'arrows', data['arrowsXs']);
                }

                if (typeof data['fadeXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'fade', data['fadeXs']);
                }

                if (typeof data['asNavForXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'asNavFor', data['asNavForXs']);
                }

                if (typeof data['centerModeSm'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'centerMode', data['centerModeSm']);
                }

                if (typeof data['centerModeMd'] != 'undefined') {
                    this.setResponsiveSetting(opts, 1024, 'centerMode', data['centerModeMd']);
                }

                if (typeof data['centerPaddingXs'] != 'undefined') {
                    this.setResponsiveSetting(opts, 768, 'centerPadding', data['centerPaddingXs']);
                }

                if (typeof data['centerPaddingMd'] != 'undefined') {
                    this.setResponsiveSetting(opts, 1024, 'centerPadding', data['centerPaddingMd']);
                }

                if (typeof data['centerPaddingLg'] != 'undefined') {
                    this.setResponsiveSetting(opts, 1366, 'centerPadding', data['centerPaddingLg']);
                }

                if (typeof data['variableWidthMd'] != 'undefined') {
                    this.setResponsiveSetting(opts, 1024, 'variableWidth', data['variableWidthMd']);
                }
            }

            return opts;
        }
    }
}

module.exports = function () {
    $.each($('.carousel'), (index, value) => {
        new Carousel(value);
    });
    $('body').on('shippingAddressCarousel:build', () => {
        new Carousel($('.checkout-address-carousel'));
        $('.checkout-address-arrowcarousel').show();
    });
    $('body').on('savedPaymentCarousel:build', () => {
        new Carousel($('.checkout-savedpayment-carousel'));
    });
    $('body').on('shippingBillingAddressCarousel:build', () => {
        new Carousel($('.checkout-billingaddress-carousel'));
        $('.checkout-billingaddress-arrowcarousel').show();
        $('.checkout-billingaddress-carousel').slick('slickGoTo', function () {
            return $('.checkout-billingaddress-carousel input:checked').closest('.slick-slide').data('slickIndex') || 0;
        }());
    });
};
