window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    //processInclude(require('base/components/menu'));
    //processInclude(require('base/components/cookie'));
    //processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    //processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/toolTip'));

    // CUSTOM COMPONENT
    processInclude(require('./components/carousel'))
    processInclude(require('./components/swiper'))
    processInclude(require('./components/header'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toastr'));
    processInclude(require('./components/inputPassword'));
    processInclude(require('./components/inputDate'));
    processInclude(require('./components/backTopBtn'));
    processInclude(require('./components/checkbox'));
});

require('./thirdParty/bootstrap');
require('./components/spinner');
require('slick-carousel/slick/slick');
require('select2/dist/js/select2');

//custom accordion checkout payments
$(document).ready(function () {
    // $(".payment-options .nav-group").click(function () {
    //     var $navItem = $(this).find('.nav-item');
    //     if(!$navItem.hasClass('disabled')) {
    //         $(".payment-options .nav-item").removeClass("active");
    //         $(".payment-options .tab-pane").removeClass("active");
    //         $navItem.addClass("active");
    //         $navItem.parent().find('.tab-pane').addClass("active");
    //     }
    // });
    //ToastMessage showed after refresh page
    var urlParams = new URLSearchParams(location.search);
    if (urlParams.has('toastr')) {
        var toastParamUrl = urlParams.get('toastr');
        var toastMessage = $('#toastMessage').attr('data-message');
        var toastMessageRemoved = $('#toastMessageRemoved').attr('data-message-removed');
        switch (toastParamUrl) {
            case ('success'):
                toastr.success(toastMessage);
                break;
            case ('removed'):
                toastr.success(toastMessageRemoved);
                break;
            default:
                toastr.error(toastMessage);
        }
    } else {
        var isPaymentError = $('#toastMessage').attr('data-payment-error');
        if (isPaymentError) {
            var toastMessage = $('#toastMessage').attr('data-message');
            toastr.error(toastMessage);
        }
    }
});