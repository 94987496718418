'use strict';

var $buttonShowHidePassword = $('.js-password-showhide');
var $inputRegisterPassword = $('.validator-password input[type=password]');

function validatePassword(selector, regex, pswd) {
    if(selector && regex && pswd != undefined) {
        if (pswd.match(regex) ) {
            selector.removeClass('invalid').addClass('valid');
        } else {
            selector.removeClass('valid').addClass('invalid');
        }
    }
}

module.exports = {
    showHidePassword: function() {
        $buttonShowHidePassword.on('click', function(e){
            var $input = $(this).prev();
            var $buttonIconsEye = $(this).find('.material-icons');
            if ($input[0].type === "password") {
                $input[0].type = "text";
                $buttonIconsEye.text('visibility_off');
            } else {
                $input[0].type = "password";
                $buttonIconsEye.text('visibility');
            }
        })
    },
    matchRegex: function() {
        $(document).ready(function() {
            $inputRegisterPassword.keyup(function() {
                var pswd = $(this).val();
                var $inputParent = $inputRegisterPassword.parent();
                //TODO passare il this con il paramter incluso 
                //validatePassword($inputRegisterPassword.parent().find('#letter'), /[A-z]/, pswd);
                validatePassword($inputRegisterPassword.parent().find('#capital'), /[A-Z]/, pswd);
                validatePassword($inputRegisterPassword.parent().find('#number'), /\d/, pswd);
                validatePassword($inputRegisterPassword.parent().find('#spaces'), /^\S+$/, pswd);
                validatePassword($inputRegisterPassword.parent().find('#length'), /^.{8,20}$/, pswd);
                validatePassword($inputRegisterPassword.parent().find('#special'), /[!@#$%&*()+\-/:;<=>?@[\\\]^_`{|}~]/, pswd);
            }).focus(function() {
                $(this).siblings('#pswd_info').show();
            }).blur(function() {
                $(this).siblings('#pswd_info').hide();
            });
        
        });
    }
};
